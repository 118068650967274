import {createRouter, createWebHistory} from 'vue-router'

const routerHistory = createWebHistory()

import Vindex from '@/views/home/index.vue' //大首页
import Vcountry from '@/views/home/country.vue' //签证国家页
import Vvisa from '@/views/home/visa.vue' //签证频道首页
import Vcontent from '@/views/home/content.vue' //文章详情页
import Vlist from '@/views/home/list.vue' //文章列表页
import Vpage from '@/views/home/page.vue' //单页

import Vregister from '@/views/member/register.vue' //注册
import Vlogin from '@/views/member/login.vue' //登录
import Vmember from '@/views/member/member.vue' //会员中心
import Vorder from '@/views/member/order.vue' //订单列表
import Vbuy from '@/views/member/buy.vue' //下单预订

// 定义路由
const routes = [
    {
        path: '/',
        name: 'index',
        component: Vindex
    },
    {
        path: '/country',
        name: 'country',
        component: Vcountry
    },
    {
        path: '/visa',
        name: 'visa',
        component: Vvisa
    },
    {
        path: '/content',
        name: 'content',
        component: Vcontent
    },
    {
        path: '/list',
        name: 'list',
        component: Vlist
    },
    {
        path: '/register',
        name: 'register',
        component: Vregister
    },
    {
        path: '/login',
        name: 'login',
        component: Vlogin
    },
    {
        path: '/member',
        name: 'member',
        component: Vmember
    },
    {
        path: '/order',
        name: 'order',
        component: Vorder
    },
    {
        path: '/page',
        name: 'page',
        component: Vpage
    },
    {
        path: '/buy',
        name: 'buy',
        component: Vbuy
    },
]

// 创建路由器
const router = createRouter({
    history: routerHistory,
    routes: routes
})


export default router;
