import http from './http.js'
export function getAd(params) {
    return http('PcAd/getList',{
        method:'POST',
        params:params
    });
}

export function getConfig(params) {
    return http('PcConfig/getList',{
        method:'POST',
        params:params
    });
}

export function getArea(params) {
    return http('Product/area',{
        method:'POST',
        params:params
    });
}

export function getCountry(params) {
    return http('Product/country',{
        method:'POST',
        params:params
    });
}

/*签证产品列表 规格连表*/
export function getVisa(params) {
    return http('Product/countryVisa',{
        method:'POST',
        params:params
    });
}

/*签证产品列表 仅国家 */
export function getVisa2(params) {
    return http('Product/countryVisa2',{
        method:'POST',
        params:params
    });
}


export function getDetail(params) {
    return http('Product/detail',{
        method:'POST',
        params:params
    });
}

export function getZl(params) {
    return http('Product/doc_list',{
        method:'POST',
        params:params
    });
}

export function getContentClassifyProduct(params) {
    return http('Content/getClassifyProdyctList',{
        method:'POST',
        params:params
    });
}

export function getContent(params) {
    return http('Content/detail',{
        method:'POST',
        params:params
    });
}

export function getContentList(params) {
    return http('Content/getList',{
        method:'POST',
        params:params
    });
}

export function getSms(params) {
    return http('Sms/send',{
        method:'POST',
        params:params
    });
}

/* 账号登录 */
export function getLoginUser(params) {
    return http('Login/saveUser',{
        method:'POST',
        params:params
    });
}

/* 账号资料 */
export function getInfoUser(params) {
    return http('Member/getInfo',{
        method:'POST',
        params:params
    });
}


/* 订单列表 */
export function getOrderList(params) {
    return http('Order/getList',{
        method:'POST',
        params:params
    });
}

/* 单页 */
export function getPage(params) {
    return http('Page/detail',{
        method:'POST',
        params:params
    });
}

/* 套餐 */
export function getWork(params) {
    return http('Product/detail_work',{
        method:'POST',
        params:params
    });
}

/* 添加联系人 */
export function savePepole(params) {
    return http('Pepole/save',{
        method:'POST',
        params:params
    });
}

/* 联系人列表 */
export function getPepole(params) {
    return http('Pepole/getList',{
        method:'POST',
        params:params
    });
}

/* 保存订单 */
export function saveOrder(params) {
    return http('Order/product',{
        method:'POST',
        params:params
    });
}


/* 注册 */
export function register(params) {
    return http('Login/register',{
        method:'POST',
        params:params
    });
}

/* 注册 保存 */
export function saveRegister(params) {
    return http('Login/saveRegister',{
        method:'POST',
        params:params
    });
}

/* 验证码 登录 */
export function getLoginCode(params) {
    return http('Login/saveCode',{
        method:'POST',
        params:params
    });
}

export function getGet(params) {
    return http('api/token/station?id='+params,{
        method:'GET',
    });
}
