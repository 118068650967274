<template>
  <view class="footer">

    <view class="footerC">
      <view class="footerCLeft">
        <view class="footerCLeftItem" v-for="(item2,index2) in footer" :key="index2" @click="gotoAd(item2)">{{ item2.title }}</view>

      </view>
      <!-- footerCLeft -->

      <view class="footerCMin">

        <view class="footerCMinLeft">客服服务</view>
        <view class="footerCMinRight">
          <view class="footerCMinRightTel">{{ config.phone }}</view>
          <view class="footerCMinRightTime">{{ config.open_time }}</view>

          <view class="footerCMinRightWecht">
            <a :href="config.wx_work" target="_blank">
              <view class="service"><el-icon ><Service /></el-icon></view>
              <view>在线客服</view>
            </a>
          </view>

        </view>

      </view>
      <!-- footerCMin -->

      <view class="footerCRight">
        <view class="footerCRightItem"  v-if="config.gzh_code">
          <view class="footerCRightItemI">
            <el-image  :src="config.gzh_code" width="120" height="120"/>
          </view>
          <view class="footerCRightItemT">公众号</view>
        </view>
        <view class="footerCRightItem"  v-if="config.xcx_code">
          <view class="footerCRightItemI">
            <el-image  :src="config.xcx_code" width="120" height="120"/>
          </view>
          <view class="footerCRightItemT">小程序</view>
        </view>
      </view>
      <!-- footerCRight -->


    </view>
    <!-- footerC -->

    <view class="footerText"><a href="https://beian.miit.gov.cn/" target="_blank">{{ config.beianhao }}</a>  Copyright© {{ config.conpany }} 2016-2024,版权所有</view>


  </view>
  <!-- footer -->

</template>

<script>
export default {
  name: "footer",
  props: {
    footer:{
      type:Array,
      default: () => []
    },
    config:{
      type:Object,
      default: () => ({ logo: '' }),
    }
  },
  methods:{
    gotoAd(i)
    {

      if(i.form==3)
      {
        //自定义
        if(i.type==1)
        {
          //跳转链接
          window.location.href=i.url;

        }
        else{
          //跳转小程序


        }
      }
      else{

        console.log('111',i.url);
        this.$router.push(i.url);

        /*
        this.$router.push({
          path: i.url
        })

         */
      }

    },
  }
}
</script>

<style scoped>

.footer {
  background: #303133;
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
 z-index: 10000;
  position: relative;
}
.footerC{
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 50px auto;
  position: relative;


}
.footerC .footerCLeft{
  width: 33.33%;
  display: flex;
  flex-wrap:wrap;
  border-right: 1px solid #606266;

}
.footerC .footerCLeft .footerCLeftItem{
  width: 40%;
  margin-top: 0px;
  flex-shrink: 0;
  height: 20px;
  vertical-align: baseline;
  cursor: pointer;
}
.footerC .footerCMin
{
  width: 33.33%;
  display: flex;
  flex-wrap: wrap;
  border-right: 1px solid #606266;
  justify-content: center;
}
.footerCMinLeft{
  width: 48%;
  display: flex;
  text-align: right;
  justify-content: right;
  margin-left: -60px;
}
.footerCMinRight{
  width:48%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}
.footerCMinRightWecht{
  border: 1px solid #ccc;
  padding: 3px 10px 3px 10px;
  border-radius: 20px;
  display: flex;
  vertical-align: center;
  margin-top: 20px;
  height: 25px;
}
.footerCMinRightWecht a{
  color:#fff;

  margin-top: 2px;
}
.service{
  margin: 3px 5px 0px 0px;
}
.footerCRight{
  width: 33.33%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.footerCRight .footerCRightItem{  width: 120px; display: flex; flex-wrap: wrap; margin-left: 40px}
.footerCRight .footerCRightItemI{ width: 120px; height: 120px; }
.footerCRight .footerCRightItemT{  width: 120px; text-align: center; margin-top: 10px}

.footerText{
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 50px auto;
  justify-content: center;
  color:#747478
}
.footerText a{
  color:#fff;
  margin-right: 20px;
}
/*移动端样式*/
@media only screen and (max-width: 1024px) {

  .footerC {
    display: flex;
    flex-wrap: wrap;
  }
  .footerC .footerCLeft{
    width: 100%;
    text-align: center;
  }
  .footerC .footerCMin{
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .footerC .footerCRight{
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
