<template>
<view class="header">

  <view class="header_box">


    <view class="header_box_left">
      <block v-if="!no">
        <view class="header_box_left_info">欢迎您，{{ config.title }}!</view>
      </block>
      <block v-else>
        <view class="header_box_left_info"><a href="/member">欢迎您，
          <text v-if="member.nickName">{{ member.nickName }}</text>
          <text v-else-if="member.phone">{{ member.phone }}</text>
          <text v-else>会员</text>
          !</a></view>
      </block>
      <view class="header_box_left_reg">
        <block v-if="!no">
          <view class="header_box_left_reg_z"><a href="/register">注册</a> </view>
          <view class="header_box_left_reg_l">|</view>
          <view class="header_box_left_reg_z"><a href="/login">登录</a></view>
        </block>
        <block v-else>
          <el-popover :visible="visibleCancel" placement="top" :width="160">
            <p>确定要退出当前登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="small" text @click="visibleCancel = false">取消</el-button>
              <el-button size="small" type="primary" @click="cannelLogin">确定</el-button
              >
            </div>
            <template #reference>
              <view style="cursor: pointer" class="header_box_left_reg_z" @click="visibleCancel = true">退出登录</view>
            </template>
          </el-popover>
        </block>
      </view>

    </view>
    <!-- header_box_left  -->

    <view class="header_box_right">
      <view class="header_box_right_c danger">
        <view class="header_box_right_c_i"><el-icon><Odometer /></el-icon></view>
        <view class="header_box_right_c_t">签证进度查询</view>

      </view>


      <view class="header_box_right_c" v-click-outside="onClickOutside3">
        <view class="header_box_right_c_i"><el-icon><Share /></el-icon></view>

        <el-popover :visible="visibleShare" placement="top" :width="160">
          <p> <el-image  :src="config.gzh_code" width="150" height="150"/></p>

          <template #reference>
            <view class="header_box_right_c_t" @mouseover="visibleShare = true"> 公众号</view>
          </template>
        </el-popover>


      </view>

    </view>
  </view>
  <!-- header_box  -->
</view>
  <!-- header  -->

  <view class="header_nav">
    <view class="header_nav_logo"> <a :href="config.domain"><el-image  :src="config.logo" width="200" height="60"/></a></view>
    <view class="header_nav_nav">
      <view class="item"  v-for="item in nav" :key="item" @click="gotoAd(item)">{{ item.title }}</view>

    </view>
  </view>
  <!-- header_nav  -->

  <view class="header_banner">
     <view class="header_banner_content">
    <el-carousel height="450px">
      <el-carousel-item v-for="item in banner" :key="item">
        <el-image  :src="item.icon "/>
      </el-carousel-item>
    </el-carousel>
     </view>

    <view class="courty">
      <view class="courty_count">
      <el-tabs  @tab-change="areaClick" v-model="activeName" :tab-position="tabPosition" type="border-card" style="height:99%; width:100%; border-right:0px;border-bottom:0px">
        <el-tab-pane  v-for="(item,index) in area" :key="item" :label="item.label" :name="index">

          <el-badge class="courtyItem" :value="item2.hot?'热门':''"  v-for="(item2,index2) in country" :key="index2">
            <a :href="'/country?country='+item2.id">   <el-button>{{ item2.label }}</el-button></a>
          </el-badge>

        </el-tab-pane>

      </el-tabs>
        </view>
    </view>

  </view>

</template>

<script>
import { getInfoUser } from '@/config/api';
export default {
  components:{
    getInfoUser
  },
  name: "index_header",
  props: {
    banner:{
      type:Array,
      default: () => []
    },
    nav:{
      type:Array,
      default: () => []
    },
    area:{
      type:Array,
      default: () => []
    },
    country:{
      type:Array,
      default: () => []
    },
    config:{
      type:Object,
      default: () => ({ logo: '' }),
    }
  },
  data() {
    return {
      tabPosition:'left',
      activeName:0,
      no:'',
      visibleCancel:false,
      visibleShare:false,
      member:{}
    }
  },
  mounted() {
    let no= localStorage.getItem('no')
    if(no && no!='')
    {
      this.no=no;
      this.getInfoUser();
    }
    else{


    }
  },
  methods:{
    cannelLogin()
    {
      console.log('退出');
      //退出登录
      localStorage.removeItem('no');
      this.no='';

    },
    onClickOutside3()
    {
      this.visibleShare=false
    },
    gotoAd(i)
    {
      if(i.form==3)
      {
        //自定义
        if(i.type==1)
        {
          //跳转链接
          window.location.href=i.url;

        }
        else{
          //跳转小程序


        }
      }
      else{

        this.$router.push(i.url);
      }

    },
    async getInfoUser()
    {
      var param={
        no:this.no
      }
      const res = await getInfoUser(param);
      if(res.code==200)
      {
        this.member=res.data.rows.userInfo;
        this.$emit('getUserinfo',res.data.rows.userInfo)
      }

    },
    areaClick(e)
    {
      var id=this.area[e].id
     // console.log(id);
      this.$emit('getCountry', id);
    }

  }
}
</script>

<style scoped>
.header{
  width: 100%;
  background-color: #fafafa;
  height: 36px;
  border-bottom: 1px solid #e4e7ed;
  display: flex;
}
.header_box{
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
  margin: 0 auto;

}
.header_box .header_box_left{ display: flex}
.header_box .header_box_left_reg{ margin-left: 20px}
.header_box .header_box_left_reg_l{ width: 1px;  margin-left:10px; margin-right:10px; }
.header_box .header_box_right{ display: flex}
.header_box .header_box_right_c{ margin-left: 20px; display: flex}
.header_box .danger{color:#e6a23c}
.header_box .header_box_right_c_i{ margin-top: 2px; margin-right: 2px}

.header_nav{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex: none;
  margin: 0 auto;
  height: 120px;
  line-height: 120px;
}
.header_nav .header_nav_logo{
  width: 200px;
  height: 80px;
  line-height: 80px;
  vertical-align: center;
  text-align: center;
  margin-top: 28px;
}
.header_nav .header_nav_nav{
  margin-left: 50px;
}

.header_nav .header_nav_nav .item{

  font-size: 20px;
  margin-right:30px;
  cursor: pointer;
}


.header_banner{
  width: 100%;
  display: flex;
  flex: none;
  margin: 0 auto;
  position: relative;

}
.header_banner_content{width: 100%}
.header_banner .courty{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  height: 90%;
  width: 500px;
  margin-left: -350px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
  display: flex;

}
.header_banner .courty_count
{

}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}
.courtyItem{ margin-right: 30px}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.courtyItem{
  margin-bottom: 10px;
}

/*移动端样式*/
@media only screen and (max-width: 1024px) {

   .header_box_left_info {
      display: none;
  }
  .header_box_right{
    margin-right: 10px;
  }
  .header_nav{
    flex-wrap: wrap;
    height:150px;
    line-height: unset;
    margin-left: 10px;
  }
  .header_nav .header_nav_nav{
    margin-left: 0px;
    width: 100%;
  }

  .header_nav .header_nav_logo{
    height: 60px;
  }
  .header_banner .courty{
    position: absolute;
    left: 160px;
    top: 50%;
    margin-left: 0px;
    z-index: 2;
    height: 90%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    display: flex;
    width: 80%;
  }


}
/*移动端样式*/
</style>
