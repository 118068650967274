import axios from 'axios';
import md5 from "js-md5";
import { ElLoading, ElMessage } from 'element-plus'
// request.js
let aurl='';
if(process.env.NODE_ENV === 'production'){
    // 生产环境
     aurl=APP_CONFIG.API_URL;
}else{
     aurl=process.env.VUE_APP_API_BASEURL
}

// 创建新的axios实例
const service = axios.create({
    // 环境变量，需要在.env文件中配置
    baseURL:aurl ,
    // 超时时间暂定10s
    timeout: 10000,
});
// request.js
// loading 次数
let loadingCount = 0;
let loading='';
service.interceptors.request.use(
    config => {
        // 加入Loading
        console.log('configconfig', config.data.loading);
        /*
        if(!config.data.loading) {
             loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            loadingCount++;
        }
        else {
            loadingCount=0;
        }

         */

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
service.interceptors.response.use(
    response => {
        // 关闭loading
        if(loadingCount>=0) {
            loadingCount--;
            console.log('loadingCount',loadingCount);
            if (loadingCount === 0) {
                 loading = ElLoading.service({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.7)',

                })
                loading.close()
            }
            else {
              //  loading.close()
            }
        }
        return response;
    },
    error => {


        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
        loading.close()
        // 处理异常情况，根据项目实际情况处理或不处理
        if (error && error.response) {
            // 根据约定的响应码处理
            switch (error.response.status) {
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 502:
                    error.message = '服务器端出错';
                    break;
                default:
                    error.message = `连接错误${error.response.status}`;
            }
        } else {
            // 超时处理
            error.message = '服务器响应超时，请刷新当前页';
        }
        ElMessage({
            message: error.message,
            type: 'warning',
        })
        return Promise.resolve(error.response);
    }
);
// request.js
const Request = (url, options = {}) => {
    let method = options.method || 'get';
    let params = options.params || {};

    params.xandomStr=Math.random().toString(16).substring(2);

    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if(flag){
        params.source='h5'; //标识来源
    }else{
        params.source='pc'; //标识来源
    }


    let sign='';
    let dateStr = ""
    let uop='546gdfg656554ygdfgfsdf55465fgdf'
    var arr = new Array();
    var num = 0;
    for (var ii in params) {
        arr[num] = ii;
        num++;
    }
    var sortArr = arr.sort();
    var sortObj = {};
    for (var i in sortArr) {
        if (typeof(params[sortArr[i]]) == 'undefined') params[sortArr[i]]='';
        sortObj[sortArr[i]] = params[sortArr[i]];
    }


    for(let key  in sortObj){

        sign=sign+key+'='+ sortObj[key]+'&';
    }

    dateStr=uop+sign
    console.log('dateStr',dateStr);
    let kkk = md5(dateStr).toUpperCase();

    params.dataSign=kkk;


    if (method === 'get' || method === 'GET') {
        return new Promise((resolve, reject) => {
            service
                .get(url, {
                    params: params,
                })
                .then(res => {
                    if (res && res.data) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            service
                .post(url, params)
                .then(res => {

                    if (res && res.data) {
                        if(res.data.code==0)
                        {
                            ElMessage({
                                message: res.data.message,
                                type: 'error',
                                duration:6000,
                                offset:200

                            })
                        }

                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};
// request.js
export default Request;
