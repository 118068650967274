<template>
<view class="page">
  <indexHeader  @getUserinfo="getUserinfo" @getCountry="getCountry" :banner="banner" :config="config" :nav="nav" :area="area" :country="country"></indexHeader>


  <view class="hot">

    <view class="hotTitle">热门签证</view>

    <view class="hotContent">

      <view class="hotContentTop">
      <el-tabs v-model="activeName"  @tab-click="handleClickHot" class="hotContentTab">
        <el-tab-pane  v-for="(item,index) in area" :key="item" :label="item.label" :name="index">

        </el-tab-pane>


      </el-tabs>

      </view>
      <!-- hotContentTop -->

      <view class="hotContentDown">

        <el-card  shadow="always"  class="downItem" v-for="(item2,index2) in countryHot" :key="index2">
          <a :href="'/country?country='+item2.id">
          <view class="downItemImage">
          <el-image  :src="item2.icon "/>
          </view>
            <view class="downItemTitle">{{item2.label}}</view>
            <view class="downItemPrice"><text>¥</text> {{item2.price}}</view>
          </a>
        </el-card>


      </view>
      <!-- hotContentDwon -->

    </view>
    <!-- hotContent -->

  </view>
  <!-- hot -->
  <allFooter :config="config" :footer="footer"></allFooter>


</view>
</template>

<script>
import indexHeader from "@/components/header/index_header";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig,getArea,getCountry } from '@/config/api';

export default {
  components:{
    indexHeader,
    allFooter
  },
  name: "homeIndex",
  data() {
    return {
       banner:[],
       nav:[],
       area:[],
       country:[],
       config:{
         phone:'',
         open_time:''
       },
       countryHot:[],
       footer:[],
       areaId:"",
       areaIdHot:'',
       activeName:0
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();
    this.getAreaData();
  },
  methods:{
    handleClickHot(e)
    {
          console.log(e);
      this.areaIdHot=this.area[e.props.name].id

      this.getCountryHot();
    },

    async  getAdData()
    {
      var param={
        ident:'index_swiper|index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {
        this.banner=res.data.rows.index_swiper
        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        document.title ='首页-'+this.config.title;
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }
    },
    async  getAreaData()
    {
      var param={

      }
      const res = await getArea(param);
      if(res.code==200)
      {
        this.area=res.data.rows;
        this.areaId=this.area[0].id
        this.areaIdHot=this.area[0].id
        this.getCountry();
        this.getCountryHot();
      }
    },
    async  getCountry(id='')
    {
      console.log('id',id);
      var top='';
      if(id) top=id
      else top=this.areaId
      var param={
        top:top,
        loading:1  // 指定不加载  loading
      }
      const res = await getCountry(param);
      if(res.code==200)
      {
        this.country=res.data.rows;

      }
    },
    async  getCountryHot()
    {

      var top=this.areaIdHot
      var param={
        top:top,
        hot:2
      }
      const res = await getCountry(param);
      if(res.code==200)
      {
        this.countryHot=res.data.rows;

      }
    }

  }
}
</script>

<style scoped>

.page{
  margin: 0px;
  padding: 0px;
}
.hot{

  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex: none;
  margin: 50px auto;
 flex-wrap: wrap;

}
.hot .hotTitle{
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  display: flex;
}
.hot .hotContent{
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}
.hot .hotContent .hotContentTop{

  text-align: center;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}
.hot .hotContent .hotContentTopItem
{
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: left;
}

/deep/.el-tabs__item {

  font-size: 16px!important;
  font-weight: bold;
}

.hotContentDown{
  width: 100%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
.hotContentDown .downItem
{
  width: 22.2%;
  margin: 0 1.31%;
  margin-top: 30px;
   display: flex;
  flex-wrap: wrap;

}
.hotContentDown .downItem:hover
{
  width: 22.2%;
  margin: 0 1.31%;
  margin-top: 30px;
  transform: translateY(-15px);
  transition: transform 1s;
}
.hotContentDown .downItem .downItemImage
{
  width: 100%;
  font-weight: bold;
  color:#f56c6c
}

.hotContentDown .downItem .downItemTitle
{
  width: 100%;
  display: flex;
  font-weight: bold;
}
.hotContentDown .downItem .downItemPrice
{
  width: 100%;
  font-weight: bold;
  color:#f56c6c
}
.hotContentDown .downItem .downItemPrice text
{
  font-size: 12px;
}


/*移动端样式*/
@media only screen and (max-width: 1024px) {
  .hotContentDown .downItem {
    width: 45%;
  }

}
</style>
