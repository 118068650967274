<template>
  <el-config-provider :locale="locale" :size="config.size" :zIndex="config.zIndex" :button="config.button">
    <router-view></router-view>
  </el-config-provider>
</template>
<script>


export default {
  name: 'App',
  data() {
    return {
      config: {
        size: "default",
        zIndex: 2000,
        button: {
          autoInsertSpace: false
        }
      }
    }
  },
}
</script>

<style>

body{
  margin: 0px;

}
#app {
  font-family:'思源黑体';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  color: #000000;
  font-size: 14px;
}
a{
  text-decoration: none;
}
a:visited  {

  color: #000;

}
a:link{

  color: #000;

}
figure{
  margin: 0px;
  padding: 0px;
}
</style>
