<template>
  <view class="sticky">
    <view class="sda">
<view class="header ">

  <view class="header_box">


    <view class="header_box_left">
      <block v-if="!no">
        <view class="header_box_left_info">欢迎您，{{ config.title }}!</view>
      </block>
      <block v-else>
      <view class="header_box_left_info"><a href="/member">欢迎您，
        <text v-if="member.nickName">{{ member.nickName }}</text>
        <text v-else-if="member.phone">{{ member.phone }}</text>
        <text v-else>会员</text>
        !</a></view>
      </block>
      <view class="header_box_left_reg">

        <block v-if="!no">
        <view class="header_box_left_reg_z"><a href="/register">注册</a> </view>
        <view class="header_box_left_reg_l">|</view>
        <view class="header_box_left_reg_z"><a href="/login">登录</a></view>
        </block>
        <block v-else>
          <el-popover :visible="visibleCancel" placement="top" :width="160">
            <p>确定要退出当前登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="small" text @click="visibleCancel = false">取消</el-button>
              <el-button size="small" type="primary" @click="cannelLogin"
              >确定</el-button
              >
            </div>
            <template #reference>
              <view style="cursor: pointer" class="header_box_left_reg_z" @click="visibleCancel = true">退出登录</view>
            </template>
          </el-popover>
        </block>

      </view>

    </view>
    <!-- header_box_left  -->

    <view class="header_box_right">
      <view class="header_box_right_c danger">
        <view class="header_box_right_c_i"><el-icon><Odometer /></el-icon></view>
        <view class="header_box_right_c_t">签证进度查询</view>

      </view>


      <view class="header_box_right_c">
        <view class="header_box_right_c_i"><el-icon><Share /></el-icon></view>
        <el-popover :visible="visibleShare" placement="top" :width="160">
          <p> <el-image  :src="config.gzh_code" width="150" height="150"/></p>

          <template #reference>
            <view class="header_box_right_c_t" @mouseover="visibleShare = true"> 公众号</view>
          </template>
        </el-popover>

      </view>

    </view>
  </view>
  <!-- header_box  -->
</view>
  <!-- header  -->

  <view class="header_nav">
    <view class="header_nav_logo">  <a :href="config.domain"><el-image  :src="config.logo" width="200" height="60"/></a></view>
    <view class="header_nav_nav">
      <view class="item"  v-for="item in nav" :key="item" @click="gotoAd(item)">{{ item.title }}</view>

    </view>
  </view>
  <!-- header_nav  -->


  </view>
  </view>

</template>

<script>
import { getInfoUser } from '@/config/api';
export default {
  components:{
    getInfoUser
  },
  name: "inside_header",
  props: {

    nav:{
      type:Array,
      default: () => []
    },

    config:{
      type:Object,
      default: () => ({ logo: '' }),
    }
  },
  data() {
    return {
      tabPosition:'left',
      activeName:0,
      no:'',
      visibleCancel:false,
      member:{},
      visibleShare:false,
    }
  },
  mounted() {
   let no= localStorage.getItem('no')
   if(no && no!='')
   {
     this.no=no;
     this.getInfoUser();
   }
  },
  methods:{
    onClickOutside3()
    {
      this.visibleShare=false
    },
    gotoAd(i)
    {
      if(i.form==3)
      {
        //自定义
        if(i.type==1)
        {
          //跳转链接
          window.location.href=i.url;

        }
        else{
          //跳转小程序


        }
      }
      else{

        this.$router.push(i.url);
      }

    },
    async getInfoUser()
    {
      var param={
        no:this.no
      }
      const res = await getInfoUser(param);
      if(res.code==200)
      {
          this.member=res.data.rows.userInfo;
          this.$emit('getUserinfo',res.data.rows.userInfo)
      }

    },
    cannelLogin()
    {
      //退出登录
      localStorage.removeItem('no');
      this.$router.push({
        path: '/'
      })

    },
    areaClick(e)
    {
      var id=this.area[e].id
     // console.log(id);
      this.$emit('getCountry', id);
    }

  }
}
</script>

<style scoped>
.header{
  width: 100%;
  background-color: #fafafa;
  height: 36px;
  border-bottom: 1px solid #e4e7ed;
  display: flex;

}
.header_box{
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
  margin: 0 auto;
  background: #fff;


}
.header_box .header_box_left{ display: flex}
.header_box .header_box_left_reg{ margin-left: 20px}
.header_box .header_box_left_reg_l{ width: 1px;  margin-left:10px; margin-right:10px; }
.header_box .header_box_right{ display: flex}
.header_box .header_box_right_c{ margin-left: 20px; display: flex}
.header_box .danger{color:#e6a23c}
.header_box .header_box_right_c_i{ margin-top: 2px; margin-right: 2px}

.header_nav{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex: none;
  margin: 0 auto;
  height: 120px;
  line-height: 120px;
  background: #fff;

}
.header_nav .header_nav_logo{
  width: 200px;
  height: 80px;
  line-height: 80px;
  vertical-align: center;
  text-align: center;
  margin-top: 28px;
}
.header_nav .header_nav_nav{
  margin-left: 50px;
}

.header_nav .header_nav_nav .item{

  font-size: 20px;
  margin-right:30px;
  cursor: pointer;
}

.sticky{
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;

}
.sda{
  box-shadow: 0 5px 5px 0 rgba(0,0,0,.05);
  background: #fff;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

/*移动端样式*/
@media only screen and (max-width: 1024px) {

  .header_box_left_info {
    display: none;
  }
  .header_box_right{
    margin-right: 10px;
  }
  .header_nav{
    flex-wrap: wrap;
    height:150px;
    line-height: unset;
    margin-left: 10px;
  }
  .header_nav .header_nav_nav{
    margin-left: 0px;
    width: 100%;
  }

  .header_nav .header_nav_logo{
    height: 60px;
  }
  .header_banner .courty{
    position: absolute;
    left: 160px;
    top: 50%;
    margin-left: 0px;
    z-index: 2;
    height: 90%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    display: flex;
    width: 80%;
  }


}
/*移动端样式*/
</style>
