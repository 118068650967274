<template>
  <insideHeader  :config="config" :nav="nav"  @getUserinfo="getUserinfo"></insideHeader>

  <main>
  <section class="box">
    <p class="title">{{ detail.title }}</p>


    <block  v-for="(item2,index2) in detail.block" :key="index2">
    <h2 v-if=" item2.title &&  item2.title!='无标题'">{{ item2.title }}</h2>

    <div>
      <div v-html="item2.content"></div>
    </div>
    </block>

  </section>
  </main>
  <allFooter :config="config" :footer="footer"></allFooter>
</template>

<script>
import insideHeader from "@/components/header/inside_header";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig,getPage} from '@/config/api';
export default {
  name: "page",
  components:{
    insideHeader,
    allFooter
  },
  data() {
    return {
      id:'',
      banner:[],
      nav:[],
      footer:[],
      detail:{}

    }
  },
  mounted()
  {

    this.getAdData();
    this.getConfigData()
  },
  created() {

  },

  watch: {
    '$route' (to, from) { //监听路由是否变化
      if(to.query.id != from.query.id){
        this.id = to.query.id;
        this.getPage();//重新加载数据
      }
    }
  },


  methods:{
    async  getAdData()
    {
      var param={
        ident:'index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {

        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        if(this.$route.query.id) {
          this.id = this.$route.query.id;
          this.getPage()
        }
      }

    },

    async  getPage()
    {
      var param={
         id:this.id
      }
      const res = await getPage(param);
      if(res.code==200)
      {
        this.detail=res.data.rows;
        document.title =this.detail.title+'-'+this.config.title;

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }

    },

  }


}
</script>

<style scoped>
main {
  background: #fff
}

main .box {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 30px;
  line-height: 2;
  min-height: 500px;
}

main .box .title {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 30px 0 0;
  font-size: 16px;
  font-weight: 700;
  color: #3d92d7;
  border-bottom: 1px solid #e4e7ed
}

main .box h2 {
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  margin: 20px 0 20px
}


/*移动端样式*/
@media only screen and (max-width: 1024px) {
  main {
    margin: 20px
  }
}
</style>
