<template>
  <ul   role="menubar" class="menus el-menu">
    <li  role="menuitem" tabindex="-1" class="el-menu-item is-active" style="padding-left: 20px;">
      <div class="el-submenu__title">
        <el-icon>
          <User />
        </el-icon><span ><a href="/member"> 用户中心 </a></span>
        <i class="el-submenu__icon-arrow el-icon-arrow-down"></i>
      </div>

    </li>
    <li  role="menuitem" aria-haspopup="true" class="el-menu-item ">
      <div class="el-submenu__title">
        <el-icon>
          <List />
        </el-icon><span ><a href="/order"> 我的订单 </a></span>
        <i class="el-submenu__icon-arrow el-icon-arrow-down"></i>
      </div>

    </li>
    <li  role="menuitem" tabindex="-1" class="el-menu-item is-disabled" style="padding-left: 20px;">
      <i  class="el-icon-shopping-bag-1"></i><span >我的钱包</span></li>


    <li  role="menuitem" tabindex="-1" class="el-menu-item is-disabled" style="padding-left: 20px;">
      <i  class="el-icon-postcard"></i><span >我的发票</span></li>

    <li  role="el-submenu__title" tabindex="-1"  class="el-menu-item ">
      <el-icon><Tickets /></el-icon><span >收件地址</span></li>

    <li  role="menuitem" tabindex="-1" class="el-menu-item is-disabled" style="padding-left: 20px;">
      <i  class="el-icon-search"></i><span >进度查询</span></li>
    <li  role="menuitem" aria-haspopup="true" class="el-submenu">
      <div class="el-menu-item ">
        <el-icon><Setting /></el-icon><span >账户设置</span>
        <i class="el-submenu__icon-arrow el-icon-arrow-down"></i></div>
      <ul role="menu" class="el-menu el-menu--inline" style="display: none;">
        <li  role="menuitem" tabindex="-1" class="el-menu-item" style="padding-left: 40px;">我的账户</li>
        <li  role="menuitem" tabindex="-1" class="el-menu-item" style="padding-left: 40px;">修改密码</li>
        <li  role="menuitem" tabindex="-1" class="el-menu-item" style="padding-left: 40px;">修改资料</li>
      </ul></li>
    <li  role="menuitem" tabindex="-1" class="el-menu-item is-disabled" style="padding-left: 20px;">
      <i  class="el-icon-bell"></i><span >系统消息</span></li>
  </ul>

</template>

<script>
export default {
  name: "left"
}
</script>

<style scoped>

</style>
