<template>
  <insideHeader  :config="config" :nav="nav"  @getUserinfo="getUserinfo"></insideHeader>

  <main >
    <div  class="cont">
      <nav  class="steps">
        <p  :class="action==1?'on':''"><i >1</i><span >验证手机号</span></p>
        <p  :class="action==2?'on':''"><i >2</i><span >填写账号信息</span></p>
        <p  class=""><i >3</i><span >注册成功</span></p>
      </nav>
      <ul  v-if="action==1">
        <li><div  class="el-input el-input-group el-input-group--append"><!---->
          <input type="text" autocomplete="off" v-model="phone" placeholder="建议使用常用的手机号码" class="el-input__inner"><!----><!---->
          <div class="el-input-group__append">
            <button  @click="startCountdown" type="button" class="el-button el-button--default"><!----><!---->
              <span>   {{ countdown > 0 ? countdown + '秒' : '发送验证码' }}</span></button></div><!----></div></li><li >
        <div  class="el-input"><!---->
          <input type="text" autocomplete="off"  v-model="code" placeholder="请输入验证码" class="el-input__inner">
          <!----><!----><!----><!----></div></li>

      </ul>

      <ul  v-if="action==2">


        <li >
        <div  class="el-input"><!---->
          <input type="text" autocomplete="off"  v-model="name" placeholder="请输入姓名" class="el-input__inner">
          </div></li>

        <li >
          <div  class="el-input"><!---->
            <input type="text" autocomplete="off"  v-model="password" placeholder="请输入密码" class="el-input__inner">
          </div></li>

        <li >
          <div  class="el-input"><!---->
            <input type="text" autocomplete="off"  v-model="okpassword" placeholder="确认密码" class="el-input__inner">
          </div></li>

      </ul>

      <div  class="btn">

        <button @click="sub" v-if="action==1"  class="next">下一步</button>

        <button  class="next" v-if="action==2" @click="save">保存</button>

      </div>

      <div  class="options">
     </div>
      </div>

 </main>
  <allFooter :config="config" :footer="footer"></allFooter>
</template>

<script>
import { ElMessage,ElLoading  } from 'element-plus'
import insideHeader from "@/components/header/inside_header";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig,getSms,register,saveRegister} from '@/config/api';
export default {
  name: "register",
  components:{
    insideHeader,
    allFooter
  },
  data() {
    return {
      action:1,
      countdown: 0,
      timer: null,
      banner:[],
      nav:[],
      footer:[],
      config:{},
      phone:'',
      code:'',
      name:'',
      password:'',
      okpassword:'',
      jump:''
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();

  },
  created() {

    if(this.$route.query.jump) {
      this.jump = decodeURIComponent(this.$route.query.jump);

    }

  },

  watch: {
    countdown: {
      handler(newVal) {
        this.countdown = newVal
      }
    }
  },
  methods:{

    async save()
    {

      if(this.action!=2)
      {
        ElMessage({
          message:'参数错误，请返回重试',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(!this.name)
      {
        ElMessage({
          message:'请输入姓名',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(!this.password)
      {
        ElMessage({
          message:'请输入密码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(this.password.length<6)
      {
        ElMessage({
          message:'密码长度必须大于6位',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(this.password!=this.okpassword)
      {
        ElMessage({
          message:'两次密码输入不一致',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }


      const loadingInstance = ElLoading.service()
      var param={
        phone:this.phone,
        code:this.code,
        name:this.name,
        password:this.password
      }
      const res = await saveRegister(param);
      if(res.code==200)
      {
        loadingInstance.close()
        ElMessage({
          message:'恭喜您，注册成功',
          type: 'success',
          duration:2000,
          offset:200

        });

        localStorage.setItem('no',res.data.rows.no)
        let _this=this;
        setTimeout(() => {
          if(_this.jump!='')
          {
            this.$router.push(_this.jump);
          }
          else {
            this.$router.push('/member');
          }
        }, 2000);



      }
      else {
        loadingInstance.close()
      }

    },
    async sub()
    {
      if(!this.phone)
      {
        ElMessage({
          message:'请输入手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(!this.code)
      {
        ElMessage({
          message:'请输入短信验证码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if (!/^1(3|4|5|6|7|8|9)[0-9]\d{8}$/.test(this.phone)) {
        ElMessage({
          message:'请输入正确的手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }
      const loadingInstance = ElLoading.service()
      var param={
        phone:this.phone,
        code:this.code
      }
      const res = await register(param);
      if(res.code==200)
      {
        loadingInstance.close()
        this.action=2;
      }
      else {
        loadingInstance.close()
      }



    },
    startCountdown() {
      if(!this.phone)
      {
        ElMessage({
          message:'请输入手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if (!/^1(3|4|5|6|7|8|9)[0-9]\d{8}$/.test(this.phone)) {
        ElMessage({
          message:'请输入正确的手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(this.timer) return false;
      this.getSms();

    },
    async  getAdData()
    {
      var param={
        ident:'visa_swiper|index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {
        this.banner=res.data.rows.visa_swiper
        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },

    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        document.title ='注册-'+this.config.title;

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }

    },
    async  getSms()
    {
      var param={
          type:'reg',
          phone:this.phone
      }
      const res = await getSms(param);
      if(res.code==200)
      {
        ElMessage({
          message:'发送成功',
          type: 'success',
          duration:2000,
          offset:200

        });

        this.countdown = 59;
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);

      }

    },

  }



}
</script>

<style scoped>
main{
  position: relative;
  min-height: 80vh;
  background: rgb(255, 255, 255);
}

main .box{
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding: 20px;
}

main .box h3{
  display: flex;
  align-items: center;
  height: 40px;
  font-size: var(--fs_xl);
  color: var(--main);
}

main .box ul{
  display: flex;
  place-content: flex-start space-between;
  flex-wrap: wrap;
  min-height: 500px;
}

main .box ul li{
  display: inline-flex;
  align-items: center;
  width: 50%;
  margin: 20px 0px 0px;
}

main .box ul li.max{
  width: 100%;
}

main .box ul li span{
  position: relative;
  width: 100px;
  flex: 0 0 auto;
  padding: 0px 0px 0px 10px;
}

main .box ul li span.need::before {
  content: "*";
  color: var(--err);
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

main .box ul li .el-input{
  width: 300px;
}

main .box ul li .el-cascader{
  width: 300px;
  margin: 0px 20px 0px 0px;
}

main .box > .el-button{
  display: flex;
  margin: 30px auto;
  width: 140px;
  align-items: center;
  justify-content: center;
}

main .cont{
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
}

main .cont .steps{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 0px auto;
}

main .cont .steps p{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 33.33%;
  position: relative;
}

main .cont .steps p::before {
  content: "";
  position: absolute;
  left: 68%;
  top: 12px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #dcdfe6;
  box-shadow: 10px 0 0 0 #dcdfe6,20px 0 0 0 #dcdfe6,30px 0 0 0 #dcdfe6,40px 0 0 0 #dcdfe6,50px 0 0 0 #dcdfe6,60px 0 0 0 #dcdfe6,70px 0 0 0 #dcdfe6;
}



main .cont .steps p:last-of-type::after, main .cont .steps p:last-of-type::before {
  content: none;
}

main .cont .steps p i{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid #dcdfe6;
  border-radius: 50%;
  color: #dcdfe6;
  font-style: normal;
}

main .cont .steps p span{
  display: inline-flex;
  width: 100%;
  justify-content: center;
  padding: 15px 0px 0px;
  color: var(--txt_secondary);
}

main .cont .steps p.on i{
  border-color: #67c23a;
  background-color: #67c23a;
  color: rgb(255, 255, 255);
  font-weight: 700;
}

main .cont .steps p.on span{
  color: var(--success);
}

main .cont ul{
  margin: 50px 0px 0px;
  width: 400px;
}

main .cont ul li{
  margin: 0px 0px 20px;
  list-style-type: none;
}

main .cont ul li span{
  display: block;
  width: 60px;
  text-align: left;
}

main .cont ul.max{
  width: 800px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

main .cont ul.max li{
  display: inline-flex;
  width: 390px;
}

main .cont ul.max li.need{
  position: relative;
}

main .cont ul.max li.need::before {
  content: "*";
  color: var(--err);
  position: absolute;
  left: 10px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  font-size: var(--fs_m);
}

main .cont ul.max li.addr{
  display: flex;
  align-items: center;
  width: 100%;
}

main .cont ul.max li.addr span{
  width: 100px;
  flex: 0 0 auto;
  text-indent: 20px;
}

main .cont ul.max li.addr .el-cascader{
  width: 290px;
  flex: 0 0 auto;
  margin: 0px 20px 0px 0px;
}

main .cont ul.max li.license{
  display: flex;
  align-items: center;
}

main .cont ul.max li.license span{
  width: 100px;
  flex: 0 0 auto;
  text-indent: 20px;
}

main .cont ul.max li.license label{
  width: 290px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid var(--bdr_bold);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

main .cont ul.max li.license label input{
  outline: none;
}

main .cont .btn{
  display: flex;
  justify-content: center;
}

main .cont .btn .next, main .cont .btn .ok, main .cont .btn .prev{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  margin: 0px 20px;
  font-size:18px;
  color: rgb(255, 255, 255);
  background-color: #3d92d7;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor:pointer;
}

main .cont .btn .ok{
  background-color: var(--warn);
}

main .cont .protocol{
  display: flex;
  align-items: baseline;
  padding: 10px 0px 20px;
}

main .cont .protocol p{
  padding: 0px 0px 0px 20px;
  text-decoration: underline;
  color: var(--txt_secondary);
  cursor: pointer;
}

main .cont .options{
  display: flex;
  margin: 20px 0px 0px;
  text-align: center;
  justify-content: center;
}

main .success{
  display: flex;
  align-items: center;
  place-content: center;
  flex-wrap: wrap;
  margin: 70px 0px 0px;
}

main .success i{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--success);
  font-size: 30px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}

main .success p{
  width: 100%;
  text-align: center;
  font-size: var(--fs_xl);
  color: var(--txt_regular);
  padding: 20px 0px 0px;
}

main .success p a{
  color: var(--main);
  text-decoration: underline;
}

.nodes{
  max-height: 600px;
  overflow: auto;
}


element.style {
}
.el-input-group--append .el-input__inner, .el-input-group__prepend {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.el-input-group>.el-input__inner {
  vertical-align: middle;
  display: table-cell;
}
.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}

</style>
