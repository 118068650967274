<template>
  <insideHeader  :config="config" :nav="nav"  @getUserinfo="getUserinfo"></insideHeader>
  <main>
  <div  class="banner">
    <div  class="country"  @mouseover="handleMouseOver2()" @mouseleave="handleMouseleave2()">
      <ul  class="options">


        <li @mouseover="handleMouseOver(item.label,item.id,index)"  v-for="(item,index) in area" :key="index"><h4 >{{ item.label }}</h4>
          <nav >
            <block v-for="(item2,index2) in item.country" :key="index2">
            <a  :href="'/country?country='+item2.id" :class="item2.hot?'hot www':'www'"   v-if="index2<=4">{{ item2.label }}</a>
            </block>

          </nav></li>


      </ul>


      <dl  class="cont" v-if="moreShow" >
        <dt >{{ chooseArea }}</dt>
        <dd   :class="item2.hot?'hot':''"  v-for="(item2,index2) in country" :key="index2">
         <a :href="'/country?country='+item2.id">{{ item2.label }}</a>
        </dd>
      </dl>


    </div>


    <div  class="ad ">



        <el-carousel>
          <el-carousel-item v-for="item in banner" :key="item">
            <el-image  :src="item.icon "/>
          </el-carousel-item>
        </el-carousel>


  </div>
   <!--  ad -->
  </div>

    <view class="hot">

      <view class="hotTitle">热门签证</view>

      <view class="hotContent">

        <view class="hotContentTop">
          <el-tabs v-model="activeName"  @tab-click="handleClickHot" class="hotContentTab">
            <el-tab-pane  v-for="(item,index) in area" :key="item" :label="item.label" :name="index">

            </el-tab-pane>


          </el-tabs>

        </view>
        <!-- hotContentTop -->

        <view class="hotContentDown">

          <el-card  shadow="always"  class="downItem" v-for="(item2,index2) in countryHot" :key="index2">
            <a :href="'/country?country='+item2.id">
              <view class="downItemImage">
                <el-image  :src="item2.icon "/>
              </view>
              <view class="downItemTitle">{{item2.label}}</view>
              <view class="downItemPrice"><text>¥</text> {{item2.price}}</view>
            </a>
          </el-card>


        </view>
        <!-- hotContentDwon -->

      </view>
      <!-- hotContent -->

    </view>
    <!-- hot -->

    <view class="ask">

      <view class="askTitle">常见问题</view>

       <view class="askContent">

         <view class="askContentItem" v-for="(item2,index2) in content" :key="index2">
         <el-card>
           <template #header>
             <div class="card-header">
               <div class="card-header-left">{{ item2.label }}</div>
               <div class="card-header-right"><a :href="'/list?id='+item2.id">查看更多></a></div>
             </div>
           </template>

           <p  class="text item" v-for="(item,index) in item2.product" :key="index">

           <a :href="'/content?id='+item.id"> {{item.title}}</a>

           </p>


         </el-card>
           </view>






       </view>
    </view>

  </main>

  <allFooter :config="config" :footer="footer"></allFooter>
</template>

<script>
import insideHeader from "@/components/header/inside_header";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig,getArea,getCountry,getContentClassifyProduct } from '@/config/api';
export default {
  name: "visa",
  components:{
    insideHeader,
    allFooter
  },
  data() {
    return {
      area:[],
      banner:[],
      nav:[],
      footer:[],
      country:[],
      countryHot:[],
      config:{},
      moreShow:false,
      chooseArea:'',
      chooseAreaId:'',
      activeName:0,
      content:[],
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();
    this.getAreaData();
    this.getContentClassifyProduct();
  },
  methods:{

    handleMouseleave2()
    {

        this.moreShow=false;


    },
    handleMouseOver2()
    {

      this.moreShow=true;
    },
    handleMouseOver(area,id,index)
    {
      console.log('handleMouseOver');
      this.chooseArea=area;
      this.chooseAreaId=id;
      this.country=[];
      this.country=this.area[index].country
    //  this.getCountry();

    },
    async  getAdData()
    {
      var param={
        ident:'visa_swiper|index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {
        this.banner=res.data.rows.visa_swiper
        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        document.title ='签证-'+this.config.title;

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }
      else{


      }
    },
    async  getAreaData()
    {
      var param={
        country:200
      }
      const res = await getArea(param);
      if(res.code==200)
      {
        this.area=res.data.rows;
        this.areaId=this.area[0].id
        this.areaIdHot=this.area[0].id
        this.getCountry();
        this.getCountryHot();
      }
    },
    async  getCountry()
    {

      var param={
        top:this.chooseAreaId,
        pageSize:200
      //  loading:1  // 指定不加载  loading
      }
      const res = await getCountry(param);
      if(res.code==200)
      {
        this.country=res.data.rows;

      }
    },
    async  getCountryHot()
    {

      var top=this.areaIdHot
      var param={
        top:top,
        hot:2
      }
      const res = await getCountry(param);
      if(res.code==200)
      {
        this.countryHot=res.data.rows;

      }
    },
    async getContentClassifyProduct()
    {
      var param={
        parent:0
      }
      const res = await getContentClassifyProduct(param);
      if(res.code==200)
      {
        this.content=res.data.rows;

      }

    }

  }




}
</script>

<style scoped>
a{
  color: #000;
  text-decoration:none;
}
.banner {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 0;
  overflow: hidden;
  display: flex;
  height: 460px
}
.banner .ad .el-carousel__container {
  height:484px
}
.banner .ad {
  width: 100%;
  margin-bottom: 10px;
  height: 384px
}

.banner .ad a {
  display: block;
  height: 100%
}

.banner .ad a img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.banner .country {
  position: relative;
  z-index: 5;
  width: 300px;
  height: 100%;
  background: hsla(0,0%,100%,.92)
}

.banner .country .options {
  width: 300px;
  height: 100%
}

.banner .country .options li {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
  height: 16.66667%;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;

}



.banner .country .options li h4 {
  font-size: 18px;
  font-weight: 700;
  color: #303133;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.banner .country .options li nav {
  display: flex;
  padding: 8px 0 0;
  font-size: 14px;
  color: #606266;
}

.banner .country .options li nav a {
  margin: 0 10px 0 0;
  flex: none;
  cursor: pointer
}

.banner .country .options li nav a.hot {
  color: #f56c6c
}

.www{
  max-width: 80px;
  overflow: hidden;
  height: 20px;
  display: flex;
}
.banner .country .cont {
  position: absolute;
  left: 300px;
  top: 0;
  width: 500px;
  height: 100%;
  padding: 20px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;

}

.banner .country .cont dt {
  width: 100%;
  flex: none;
  font-size: 24px;
  color: #333;
  margin: 0 0 10px;
  font-weight: bold;
  padding: 0px;
}

.banner .country .cont dd{
  padding: 10px;
  cursor: pointer;
  margin: 0px;
}

.banner .country .cont dd.hot {
  color:#f56c6c;
  position: relative
}

.banner .country .cont dd.hot:before {
  content: "HOT";
  padding: 2px 3px 1px;
  position: absolute;
  left: 50%;
  top: -5px;
  background-color: #f56c6c;
  font-size: 8px;
  font-weight: 700;
  color: #fff
}

.banner .country .cont dd.hot:after {

}

.banner .country .cont dd:hover {
  text-decoration: none
}
.hot{

  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex: none;
  margin: 50px auto;
  flex-wrap: wrap;

}
.hot .hotTitle{
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  display: flex;
}
.hot .hotContent{
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}
.hot .hotContent .hotContentTop{

  text-align: center;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}
.hot .hotContent .hotContentTopItem
{
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: left;
}

/deep/.el-tabs__item {

  font-size: 16px!important;
  font-weight: bold;
}

.hotContentDown{
  width: 100%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
.hotContentDown .downItem
{
  width: 22.2%;
  margin: 0 1.31%;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

}
.hotContentDown .downItem:hover
{
  width: 22.2%;
  margin: 0 1.31%;
  margin-top: 30px;
  transform: translateY(-15px);
  transition: transform 1s;
}
.hotContentDown .downItem .downItemImage
{
  width: 100%;
  font-weight: bold;
  color:#f56c6c
}

.hotContentDown .downItem .downItemTitle
{
  width: 100%;
  display: flex;
  font-weight: bold;
}
.hotContentDown .downItem .downItemPrice
{
  width: 100%;
  font-weight: bold;
  color:#f56c6c
}
.hotContentDown .downItem .downItemPrice text
{
  font-size: 12px;
}


.ask{

  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex: none;
  margin: 50px auto;
  flex-wrap: wrap;

}
.ask .askTitle{
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  display: flex;
}
.ask .askContent {

 width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

}
.ask .askContent .askContentItem
{
  width: 48%;
}
.ask .askContent .askContentItem:nth-child(odd)
{
    margin-right: 20px;
    margin-bottom: 20px;
}
.card-header{
  display: flex;
  justify-content: space-between;
  .card-header-left {
   font-weight: bold;
    font-size: 16px;
  }
}
ul{
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 1200px) {
  main {
    width:auto!important
  }

  main .searchs {
    display: flex;
    justify-content: center
  }

  .banner {
    height: auto;
    flex-direction: column
  }

  .banner .ad {
    height: 300px
  }

  .banner .country {
    order: 2;
    width: 96%;
    margin: 10px auto;
    box-shadow: 0 -2px 3px 4px rgba(0,0,0,.6);
    overflow: hidden
  }

  .banner .country .options {
    width: 35%
  }

  .banner .country .options li {
    padding: 10px 20px
  }

  .banner .country .options li nav a {
    margin: 0;
    padding: 3px 5px
  }

  .banner .country .cont {
    width: 65%;
    left: unset;
    right: 0
  }

  .issue ul {
    width: 90%;
    margin: 0 auto
  }

  .issue ul li {
    width: 100%
  }

  section h2 {
    font-size: 30px
  }
}

@media only screen and (max-width: 1024px) {
  .banner .ad a {
    height:100%
  }

  .banner .ad a img {
    -o-object-fit: cover;
    object-fit: cover
  }

  .banner .country .options li {
    border: none
  }

  .recommend ul li {
    width: 32%;
    margin: 0 .66667%
  }

  section h2 {
    font-size: 28px
  }
}

@media only screen and (max-width: 768px) {
  .banner .ad a img {
    -o-object-fit:contain;
    object-fit: contain
  }
  .ask .askContent .askContentItem {
    width: 47%;
  }
  .banner .country .options {
    display: flex;
    flex-wrap: wrap;
    width: 100%
  }

  .banner .country .options li {
    width: 100%;
    position: relative
  }

  .banner .country .options li:after {
    content: "查看更多";
    display: block;
    position: absolute;
    right: 2%;
    top: 50%
  }

  section h2 {
    font-size: 22px
  }

  .recommend nav {
    width: 100%;
    margin: 0 auto 20px;
    justify-content: space-around
  }

  .recommend nav a {
    margin: -40px 10px 0;
    font-size: 14px;
    text-align: center
  }

  .recommend nav .on {
    font-weight: 400!important
  }

  .recommend ul li {
    width: 48%;
    margin: 0 1%
  }

  .recommend ul li a img {
    height: auto
  }

  .recommend ul li a figure b {
    padding-top: 4px
  }
}

@media only screen and (max-width: 480px) {
  main .searchs .search {
    width:96%
  }

  .banner .country .options li {
    padding: 8px 16px;
    border-bottom: 1px solid var(--bdr_light)
  }

  .banner .country .options li h4 {
    font-size: var(--mobileTitle)
  }
 .el-carousel__container .el-image {

    height: 100%;
  }

  .banner .ad a img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }


  section h2 {
    font-size: var(--mobileTitle);
    padding: 30px 0 40px
  }

  section h2 a {
    font-size: var(--mobileSmallF)
  }

  .recommend nav a {
    padding: 10px 0;
    font-size: 12px
  }

  .recommend ul li {
    width: 46%;
    margin: 0 2%
  }

  .recommend ul li a figure {
    padding: 8px 12px
  }

  .recommend ul li a figure b {
    font-size: var(--mobileSmallF)
  }

  .issue ul {
    width: 96%
  }

  .issue ul li {
    padding: 10px
  }
}

/deep/ .el-carousel__container {
  height: 460px
}

@media only screen and (max-width: 1200px) {
  /deep/ .banner .ad .el-carousel__container {
    height:400px
  }
}

@media only screen and (max-width: 1024px) {
  /deep/ .banner .ad .el-carousel__container {
    height:300px
  }
}

</style>
