<template>
  <insideHeader  @getUserinfo="getUserinfo"  :config="config" :nav="nav"></insideHeader>

  <main ><div  class="box"><header ><h3 >{{ detail.title }}</h3></header>
    <div  class="cont">
      <div v-html="detail.introduce"></div>

    </div>
  </div>
  </main>
  <allFooter :config="config" :footer="footer"></allFooter>
</template>

<script>
import insideHeader from "@/components/header/inside_header";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig,getContent} from '@/config/api';
export default {
  name: "content",
  components:{
    insideHeader,
    allFooter
  },
  data() {
    return {
      id:'',
      nav: [],
      footer: [],
      config: {},
      detail:{
        title:'',
        introduce:''
      }
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();

  },
  created() {
    if(this.$route.query.id) {
      this.id = this.$route.query.id;

    }
  },
  methods:{

    async  getAdData()
    {
      var param={
        ident:'visa_swiper|index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {

        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        this.getContent()
      }
    },
    async  getContent()
    {
      var param={
         id:this.id
      }
      const res = await getContent(param);
      if(res.code==200)
      {
        this.detail=res.data.rows;
        document.title = this.detail.title+'-'+this.config.title;
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }
    },

  }
}
</script>

<style scoped>

body {
  line-height: 1
}

ol,ul {
  list-style: none
}

blockquote,q {
  quotes: none
}

blockquote:after,blockquote:before,q:after,q:before {
  content: "";
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

a {
  text-decoration: none
}

a:hover {
  text-decoration: underline
}

button {
  border: none
}


@media only screen and (max-width: 480px) {
  .search .list,.search dl {
    width:100%;
    left: 0
  }
}

header{
  display: flex;
  flex-wrap: wrap;
  position: relative
}



header .info {
  height: 120px;
  background-color: #fff
}

header .info .nav {
  display: flex;
  align-items: center
}

header .info .nav .logo {
  width: 200px
}

header .info .nav .logo img {
  width: 100%
}

header .info .nav nav {
  display: flex;
  align-items: center
}

header .info .nav nav a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 25px;

}


header .info .nav nav a.on {

}
header h3
{
  font-size: 20px;
}
header .info .nav nav a.on:before {
  transform: translateX(-50%) scale(1)
}

header .info .nav nav a:hover {
  text-decoration: none;

}

.box {
 margin-top: 30px;
  z-index: 1;
}

@media only screen and (max-width: 1024px) {
  header .toolba{
    height:unset;
    padding: 10px 0
  }

  header .toolbar .toolbar_box {
    flex-wrap: wrap
  }

  header .info .nav nav a {
    font-size: 16px
  }
}

@media only screen and (max-width: 768px) {
  header .toolbar p>span:first-of-type {
    display:none
  }

  header .toolbar p>a {
    display: inline-block;
    padding: 5px 10px
  }

  header .info .info_box {
    max-width: 100%
  }

  header .info .info_box .nav {
    width: 100%;
    flex-wrap: wrap
  }

  header .info .info_box .nav .logo {
    display: inline-block;
    margin-left: 10px
  }

  header .info .info_box .nav nav {
    width: 100%;
    justify-content: space-around
  }

  header .info .info_box .nav nav a {
    width: 100%;
    padding: 0
  }
}

@media only screen and (max-width: 480px) {
  header .toolbar .icon-telephone {
    display:none
  }

  header .toolbar p {
    flex-wrap: wrap
  }

  header .toolbar .concatPhone {
    display: none
  }

  header .info .info_box .nav .logo {
    width: 35%
  }

  header .info .info_box .nav nav {
    margin: 6px auto 0
  }

  header .info .info_box .nav nav a {
    padding: 8px 0;
    height: auto
  }
}


@media only screen and (max-width: 450px) {
  .footer_box figure[data-v-3c0c57b2] {
    padding:0 10px
  }

  .footer_box .bottom[data-v-3c0c57b2] {
    padding: 10px
  }
}

@media only screen and (max-width: 3840px) {
  #app>main {
    width:1200px;
    margin: 0 auto
  }
}

@media only screen and (max-width: 1024px) {
  #app>main {
    width:768px;
    padding: 0 2%
  }
}

@media only screen and (max-width: 768px) {
  #app>main {
    width:100%
  }
}

</style>
