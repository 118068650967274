<template>
  <insideHeader  :config="config" :nav="nav"  @getUserinfo="getUserinfo"></insideHeader>

  <main >
    <div  class="box">
      <header >
        <h3 >{{ detail.label }}</h3>
        <div  class="el-input el-input-group el-input-group--append"><!---->
          <input type="text" v-model="keyword" autocomplete="off" placeholder="请输入搜索关键词" class="el-input__inner"/><!----><!---->
          <div class="el-input-group__append">
            <button  type="button" class="el-button el-button--default" @click="search"><!----><!----><span>搜索</span></button></div><!----></div>
      </header>
      <ul >

        <li  v-for="(item2,index2) in list" :key="index2"><a :href="'/content?id='+item2.id"> <h4 ><span >{{ item2.title }}</span></h4></a></li>

      </ul>
      <div  class="el-pagination is-background">

        <el-pagination @change="changePage" background layout="prev, pager, next" :total="count" :page-size="pageSize"/>

      </div>
    </div>
  </main>
  <allFooter :config="config" :footer="footer"></allFooter>

</template>


<script>
import insideHeader from "@/components/header/inside_header";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig,getContentList} from '@/config/api';
export default {
  name: "list",
  components:{
    insideHeader,
    allFooter
  },
  data() {
    return {
      id:'',
      nav: [],
      footer: [],
      config: {},
      list:[],
      count:0,
      pageSize:20,
      page:1,
      keyword:'',
      detail:{

      }
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();

  },
  created() {
    if(this.$route.query.id) {
      this.id = this.$route.query.id;

    }
  },
  methods:{
    search()
    {

      this.getContentList();
    },
    changePage(e)
    {
       this.page=e
       this.getContentList();
    },
    async  getAdData()
    {
      var param={
        ident:'visa_swiper|index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {

        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        this.getContentList();
      }
    },
    async  getContentList()
    {
      var param={
        id:this.id,
        pageSize:this.pageSize,
        page:this.page,
        keyword:this.keyword
      }
      const res = await getContentList(param);
      if(res.code==200)
      {
        console.log('res.data.rows',res.data.rows);
        this.list=res.data.rows.list;
        this.detail=res.data.rows.detail;
        this.count=res.data.rows.count;
        document.title = this.detail.label+'-'+this.config.title;

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    },

  }
}
</script>

<style scoped>
main {
  width: 100%;
  background-color: #fff
}
a{
  color: #000;
}
.box {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden
}

.box header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin: 30px 0 0
}

.box header h3 {
  font-size: 20px;
  font-weight: 700;
  flex: none
}

.box header .el-input {
  width: 400px
}

.box ul {
  counter-reset: section;
  padding: 0 0 30px
}

.box ul:empty {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 100px 0
}



.box ul li {
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin: 15px 0 0
}

.box ul li h4 {
  display: flex;
  align-items: center
}


.box ul li h4 span {
  padding: 0 0 0 15px;
  font-size: 18px;
  font-weight: 500;

}

.box ul li .cont {
  line-height: 1.6;


  padding: 10px 0 0
}

.box .el-pagination {
  display: flex;
  justify-content: center;
  margin: 0 0 50px
}

.box .cont {

  line-height: 1.6;
  padding: 0 0 50px
}

@media only screen and (max-width: 768px) {
  main {
    box-sizing:border-box
  }
}

.el-input-group--append .el-input__inner, .el-input-group__prepend {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.el-input-group>.el-input__inner {
  vertical-align: middle;
  display: table-cell;
}
.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}
</style>
