<template>

  <main :style="'background: url('+config.login_bg+') center top / cover no-repeat;'" >
    <div  class="content">
      <section  class="box">
        <h3 ><a  href="/" class="">
          <img  :src="config.logo" alt=""></a></h3>
        <div  class="form"><div  class="el-input"><!---->
          <input type="text" v-model="phone" autocomplete="off" maxlength="11" placeholder="请输入手机" class="el-input__inner">
          <!----><!----><!----><!----></div>
          <div  class="el-input" v-if="type==1"><!---->
            <input type="password" v-model="password" autocomplete="off" placeholder="请输入密码" class="el-input__inner">
            <!----><!----><!----><!----></div>
          <div  class="el-input el-input-group el-input-group--append" v-if="type==2"><!---->
            <input type="text" autocomplete="off" v-model="code" maxlength="5" placeholder="请输入验证码" class="el-input__inner">
            <!----><!----><div class="el-input-group__append">

              <button  type="button" class="el-button el-button--default" @click="startCountdown">
                <!----><!----><span>  {{ countdown > 0 ? countdown + '秒' : '发送验证码' }}</span></button></div><!----></div></div>

        <div  class="link">
          <a  href="/register" class="">免费注册</a></div>
        <button v-if="type==1" type="button" class="el-button go el-button--warning" @click="sub">
          <!----><!----><span>登录</span></button>

        <button v-if="type==2" type="button" class="el-button go el-button--warning" @click="subCode">
          <!----><!----><span>登录</span></button>

        <div  class="tab">
        <button @click="changeType(1)"  :class="type==1?'on':''" style="cursor: pointer"> 账号密码登录 </button><button @click="changeType(2)"   :class="type==2?'on':''" style="cursor: pointer"> 手机动态登录 </button></div>
      </section>
      <section  class="reset">
        <div  class="reset_box">
          <h3 >找回密码 <i  class="icon-clear"></i></h3><ul ><li ><span >手机号：</span>
          <div  class="el-input el-input-group el-input-group--append"><!---->
            <input type="text" autocomplete="off" class="el-input__inner"><!----><!---->
            <div class="el-input-group__append">


              <button  type="button" class="el-button el-button--default">
              <!----><!----><span>获取验证码</span></button></div><!----></div></li>

          <li ><span >验证码：</span>
          <div  class="el-input"><!---->
            <input type="text" autocomplete="off" class="el-input__inner"><!----><!----><!----><!----></div></li>
          <li ><span >密码：</span><div  class="el-input"><!---->
            <input type="password" autocomplete="off" class="el-input__inner"><!----><!----><!----><!----></div></li>
          <li ><span >确认密码：</span><div  class="el-input"><!---->
            <input type="password" autocomplete="off" class="el-input__inner"><!----><!----><!----><!----></div></li>
        </ul>
          <div  class="btn"><button  type="button" class="el-button el-button--warning"><!----><!----><span>下一步</span>
          </button><button  type="button" class="el-button el-button--success"><!----><!----><span>完成</span></button>
          </div></div></section></div></main>
</template>

<script>
import { ElMessage,ElLoading } from 'element-plus'

import { getAd,getConfig,getSms,getLoginUser,getLoginCode} from '@/config/api';


export default {
  name: "login",
  components:{


  },
  data() {
    return {

      type:1,
      countdown: 0,
      timer: null,
      banner:[],
      nav:[],
      footer:[],
      config:{},
      phone:'',
      code:'',
      password:'',
      jump:''
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();

  },
  created() {

    if(this.$route.query.jump) {
      this.jump = decodeURIComponent(this.$route.query.jump);

    }

  },
  watch: {
    countdown: {
      handler(newVal) {
        this.countdown = newVal
      }
    }
  },
  methods:{
    async subCode()
    {
      //手机验证码登录
      if(!this.phone)
      {
        ElMessage({
          message:'请输入手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if (!/^1(3|4|5|6|7|8|9)[0-9]\d{8}$/.test(this.phone)) {
        ElMessage({
          message:'请输入正确的手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(!this.code)
      {
        ElMessage({
          message:'请输入验证码',
          type: 'error',
          duration:3000,
          offset:200
        });
        return false;
      }
      var param={
        phone:this.phone,
        code:this.code
      }
      const loadingInstance = ElLoading.service()
      const res = await getLoginCode(param);
      if(res.code==200)
      {
        loadingInstance.close()
        localStorage.setItem('no',res.data.rows.no)
        ElMessage({
          message:'恭喜您，登录成功',
          type: 'success',
          duration:2000,
          offset:200

        });
        let _this=this;
        setTimeout(() => {
          if(_this.jump!='')
          {
            this.$router.push(_this.jump);
          }
          else {
            this.$router.push('/member');
          }
        }, 2000);

      }
      else {

        loadingInstance.close()
      }


    },
    changeType(i)
    {
      this.type=i
    },
    sub()
    {
      if(!this.phone)
      {
        ElMessage({
          message:'请输入手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(!this.password)
      {
        ElMessage({
          message:'请输入密码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if (!/^1(3|4|5|6|7|8|9)[0-9]\d{8}$/.test(this.phone)) {
        ElMessage({
          message:'请输入正确的手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

       this.getLoginUser();

    },
    startCountdown() {
      if(!this.phone)
      {
        ElMessage({
          message:'请输入手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if (!/^1(3|4|5|6|7|8|9)[0-9]\d{8}$/.test(this.phone)) {
        ElMessage({
          message:'请输入正确的手机号码',
          type: 'error',
          duration:3000,
          offset:200

        });
        return false;
      }

      if(this.timer) return false;
      this.getSms();

    },
    async  getAdData()
    {
      var param={
        ident:'visa_swiper|index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {
        this.banner=res.data.rows.visa_swiper
        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },

    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        document.title ='登录-'+this.config.title;

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }

    },
    async getLoginUser()
    {

      var param={
        phone:this.phone,
        password:this.password
      }
      const loadingInstance = ElLoading.service()
      const res = await getLoginUser(param);
      if(res.code==200)
      {
        loadingInstance.close()
        localStorage.setItem('no',res.data.rows.no)
        ElMessage({
          message:'恭喜您，登录成功',
          type: 'success',
          duration:2000,
          offset:200

        });
        let _this=this;
        setTimeout(() => {
          if(_this.jump!='')
          {
            this.$router.push(_this.jump);
          }
          else {
            this.$router.push('/member');
          }
        }, 2000);

      }
      else{
        loadingInstance.close()
      }

    },
    async  getSms()
    {
      var param={
        type:'login',
        phone:this.phone
      }
      const res = await getSms(param);
      if(res.code==200)
      {
        this.countdown = 59;
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);

      }

    },

  }



}
</script>

<style scoped>
main {
  width: 100vw;
  height: 100vh;
}

main::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  filter: blur(3px);
  z-index: -1;

}

main .content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

main::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

main .box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 20px 20px 50px;
  border-radius: 10px;
  overflow: hidden;
  width: 320px;
}

main .box h3 {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 10px 0px;
}

main .box h3 img {
  height: 80px;
}

main .box .form .el-input {
  margin: 20px 0px 0px;
}

main .box .link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

main .box .link a {
  color: var(--txt_placeholder);
}

main .box .go {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: 0px auto 30px;
  font-size: var(--fs_xl);
}

main .box .tab {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e4e7ed;
}

main .box .tab button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50px;
  border-radius: 0px;
  border: none;
  outline: none;
  background-color: #f2f6fc;
  font-size: var(--fs-m);
  color: #3d92d7;
  font-weight: 700;
}

main .box .tab button.on {
  background-color: #3d92d7;
  color: rgb(255, 255, 255);
}

main .reset {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

main .reset.show {
  opacity: 1;
  visibility: visible;
}

main .reset.show .reset_box {
  transform: translate(-50%, -50%) scale(1);
}

main .reset .reset_box {
  width: 360px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.3s ease 0s;
  background: rgb(255, 255, 255);
  padding: 0px 20px 20px;
  border-radius: 10px;
}

main .reset .reset_box h3 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: var(--fs_xl);
  font-weight: 700;
  color: var(--main);
}

main .reset .reset_box h3 i {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--fs_l);
  color: var(--txt_regular);
  padding: 10px;
  cursor: pointer;
}

main .reset .reset_box ul li {
  display: flex;
  align-items: center;
  margin: 0px 0px 20px;
}

main .reset .reset_box ul li span {
  width: 100px;
  flex: 0 0 auto;
}

main .reset .reset_box .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  main .box {
    left: calc(50% - 160px);
    top: 30%;
    transform: translate(0px);
  }
}

@media only screen and (max-width: 768px) {
  main {
    padding: 0px !important;
  }

  main .box {
    position: relative;
    left: auto;
    top: auto;
  }
}

@media only screen and (max-width: 480px) {
  main .box .go {
    font-size: var(--mobileMainF);
  }
}
.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}
</style>
