<template>
  <insideHeader  @getUserinfo="getUserinfo"  :config="config" :nav="nav"></insideHeader>

  <main  class="">
    <div  aria-label="Breadcrumb" role="navigation" class="el-breadcrumb">
      <span  class="el-breadcrumb__item">
        <span role="link" class="el-breadcrumb__inner is-link">签证</span>
       <el-icon><ArrowRight /></el-icon></span>
      <span  class="el-breadcrumb__item"><span role="link" class="el-breadcrumb__inner">签证详情</span>
       <el-icon><ArrowRight /></el-icon></span>
      <span  class="el-breadcrumb__item" aria-current="page">
        <span role="link" class="el-breadcrumb__inner">提交订单</span>
        <i class="el-breadcrumb__separator el-icon-arrow-right"></i></span></div>
    <div  class="el-steps el-steps--simple">
      <div  class="el-step is-simple" style="flex-basis: 50%; margin-right: 0px;">
        <div class="el-step__head is-finish"><div class="el-step__line" style="margin-right: 0px;">
          <i class="el-step__line-inner" style="transition-delay: 0ms; border-width: 0px; width: 0%;"></i></div>


          <div class="el-step__icon is-icon"><el-icon size="20"><EditPen /></el-icon><!----></div></div>
        <div class="el-step__main"><div class="el-step__title is-finish">提交订单</div><div class="el-step__arrow"></div>


        </div></div><div  class="el-step is-simple" style="flex-basis: 50%; margin-right: 0px;">
      <div class="el-step__head is-process"><div class="el-step__line" style="margin-right: 0px;">
        <i class="el-step__line-inner" style="transition-delay: -150ms; border-width: 0px; width: 0%;"></i></div>

        <div class="el-step__icon is-icon"><el-icon size="20"><Money /></el-icon><!----></div></div>
      <div class="el-step__main"><div class="el-step__title is-process">在线支付</div><div class="el-step__arrow"></div>

      </div></div><div  class="el-step is-simple is-flex" style="flex-basis: 50%; max-width: 33.3333%;">
      <div class="el-step__head is-wait"><div class="el-step__line"><i class="el-step__line-inner"></i></div>

        <div class="el-step__icon is-icon"><el-icon size="20"><Select /></el-icon><!----></div></div>
      <div class="el-step__main"><div class="el-step__title is-wait">完成</div><div class="el-step__arrow"></div></div>

    </div></div><section  style="display: none;"><h4 >结算方式</h4><div  role="radiogroup" class="el-radio-group">
    <label  role="radio" tabindex="-1" class="el-radio"><span class="el-radio__input"><span class="el-radio__inner">

    </span><input type="radio" aria-hidden="true" tabindex="-1" class="el-radio__original" value="dealer_price"></span>
      <span class="el-radio__label">分销商价<!----></span></label>
    <label  role="radio" aria-checked="true" tabindex="0" class="el-radio is-checked">
      <span class="el-radio__input is-checked"><span class="el-radio__inner"></span>
        <input type="radio" aria-hidden="true" tabindex="-1" class="el-radio__original" value="retail_price"></span>
      <span class="el-radio__label">零售价<!----></span></label></div></section>
    <section  class="order"><h4 >订单信息</h4>
      <div  class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition">
        <div class="hidden-columns"><div ></div><div ></div><div ></div><div ></div><div >
          <span  class="bold">860.00</span></div></div><div class="el-table__header-wrapper">
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__header" style="width: 1160px;"><colgroup>
          <col name="el-table_5_column_21" width="232"><col name="el-table_5_column_22" width="232">
          <col name="el-table_5_column_23" width="232"><col name="el-table_5_column_24" width="232">
          <col name="el-table_5_column_25" width="232"><col name="gutter" width="0"></colgroup>
          <thead class="has-gutter"><tr class=""><th colspan="1" rowspan="1" class="el-table_5_column_21     is-leaf">
            <div class="cell">套餐内容</div></th>
            <th colspan="1" rowspan="1" class="el-table_5_column_22  is-center   is-leaf">
              <div class="cell">出行日期</div></th>
            <th colspan="1" rowspan="1" class="el-table_5_column_23  is-center   is-leaf">
              <div class="cell">单价/元</div></th>
            <th colspan="1" rowspan="1" class="el-table_5_column_24  is-center   is-leaf"><div class="cell">数量</div>
            </th><th colspan="1" rowspan="1" class="el-table_5_column_25  is-right   is-leaf">
              <div class="cell">合计</div></th><th class="gutter" style="width: 0px; display: none;"></th></tr></thead>
        </table></div><div class="el-table__body-wrapper is-scrolling-none">
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__body" style="width: 1160px;"><colgroup>
          <col name="el-table_5_column_21" width="232"><col name="el-table_5_column_22" width="232">
          <col name="el-table_5_column_23" width="232"><col name="el-table_5_column_24" width="232">
          <col name="el-table_5_column_25" width="232"></colgroup><tbody><tr class="el-table__row">
          <td rowspan="1" colspan="1" class="el-table_5_column_21  "><div class="cell">{{ detail.title }}-{{ detail.w_title }}</div></td>
          <td rowspan="1" colspan="1" class="el-table_5_column_22 is-center "><div class="cell">{{ date }}</div></td>
          <td rowspan="1" colspan="1" class="el-table_5_column_23 is-center "><div class="cell"> {{ detail.price }} </div></td>
          <td rowspan="1" colspan="1" class="el-table_5_column_24 is-center "><div class="cell">{{ num }}</div></td>
          <td rowspan="1" colspan="1" class="el-table_5_column_25 is-right "><div class="cell">
            <span  class="bold">{{ allPrice }}</span></div></td></tr><!----></tbody></table><!----><!----></div>
        <!----><!----><!----><!----><div class="el-table__column-resize-proxy" style="display: none;"></div>
      </div>

      <figure  class="people"><p >选择申请人</p>
        <el-radio-group v-model="sqr" size="large">
        <ul  class="data">
          <li v-for="(item2,index2) in people" :key="index2"><el-radio :value="index2"><span >{{ item2.name }}</span><text v-if="item2.shenfen">（{{item2.shenfen}}）</text></el-radio></li>

        </ul>
        </el-radio-group>

       <view style="margin-top: 20px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;"> <el-button @click="add">添加申请人</el-button> </view>
      </figure>

      <figure  class="people"><p >填写联系人</p><ul  class="data"><li ><span >姓名：</span>
        <div  class="el-input"><!----><input type="text" v-model="name" autocomplete="off" class="el-input__inner">
          <!----><!----><!----><!----></div></li><li ><span >手机号：</span><div  class="el-input"><!---->
        <input v-model="phone" type="text" autocomplete="off" class="el-input__inner"><!----><!----><!----><!----></div></li><li >
        <span >备注：</span><div  class="el-input"><!----><input v-model="bz" type="text" autocomplete="off" class="el-input__inner">
        <!----><!----><!----><!----></div></li></ul></figure>


    </section><!----><section  class="pay">
    <figure ><p ><span >订单费用：</span><b >{{allPrice}}</b></p><p ><span >总计：</span><b >{{allPrice}}</b></p></figure>
    <div  class="btn"><label  class="el-checkbox"><span class="el-checkbox__input"><span class="el-checkbox__inner">

    </span></span>
    </label>
      <button  type="button" class="el-button el-button--danger is-round" @click="sub"><!----><!----><span>提交订单</span></button>
    </div></section><div  class="el-dialog__wrapper" style="display: none;">
    <div role="dialog" aria-modal="true" aria-label="dialog" class="el-dialog" style="margin-top: 15vh; width: 800px;">
      <div class="el-dialog__header"><span class="el-dialog__title"></span>
        <button type="button" aria-label="Close" class="el-dialog__headerbtn">
          <i class="el-dialog__close el-icon el-icon-close"></i></button></div><!----><!----></div></div>
    <div class="el-loading-mask" style="display: none;"><div class="el-loading-spinner">
      <svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
      </svg><!----></div></div></main>

  <allFooter :config="config" :footer="footer"></allFooter>

  <el-dialog v-model="addSqr" title="添加申请人">

    <el-form :model="form" label-width="auto">
      <el-form-item label="申请人姓名">
        <el-input v-model="form.name" />
      </el-form-item>

      <el-form-item label="申请人身份">
        <el-radio-group v-model="form.shenfen">
          <el-radio value="在职人员">在职人员</el-radio>
          <el-radio value="退休人员">退休人员</el-radio>
          <el-radio value="自由职业者">自由职业者</el-radio>
          <el-radio value="学生">学生</el-radio>
          <el-radio value="学龄前儿童">学龄前儿童</el-radio>
          <el-radio value="无业人员">无业人员</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="addSqr = false">取消</el-button>
        <el-button type="primary" @click="addSave">
          确定添加
        </el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script>
import { ElMessage,ElLoading  } from 'element-plus'
import insideHeader from "@/components/header/inside_header";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig,getWork,savePepole,getPepole,saveOrder} from '@/config/api';
export default {
  name: "buy",
  components:{
    insideHeader,
    allFooter
  },
  data() {
    return {
      no:'',
      sqr:'',
      agr:false,
      addSqr:false,
      allPrice:0,
      work_id:'',
      num:'',
      date:'',
      phone:'',
      name:'',
      people:[],
      bz:'',
      nav: [],
      form:{},
      footer: [],
      config: {},
      detail:{
        title:'',
        introduce:''
      }
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();
    this.getPepole();

  },
  created() {
    let no= localStorage.getItem('no')
    if(!no || no=='')
    {
      this.$router.push({
        path: '/login'
      })
    }
    this.no=no;
    if(this.$route.query.work_id) {
      this.work_id = this.$route.query.work_id;
      this.num = this.$route.query.num;
      this.date=this.$route.query.date;
      if(this.num<=0 || isNaN(this.num))  this.num=1;
    }
    else {

      ElMessage({
        message:'参数错误',
        type: 'error',
        duration:2000,
        offset:200

      });

      setTimeout(() => {
        this.$router.push({
          path: '/'
        })
      }, 2000);

    }
  },
  methods:{
    async saveOrder()
    {
      var pepole=[];
      pepole[0]={'id':this.people[this.sqr].id,'shenfen':this.people[this.sqr].shenfen};

      var param={
        no:this.no,
        pepole:JSON.stringify(pepole),
        work:this.work_id,
        date:this.date,
        allPrice:this.allPrice
      }
      const loadingInstance = ElLoading.service()
      const res = await saveOrder(param);
      if(res.code==200)
      {
        loadingInstance.close()
        ElMessage({
          type: 'success',
          message: `订单提交成功`,
        })
        setTimeout(() => {

          this.$router.push('/order');
        }, 2000);
      }
      else {
        loadingInstance.close()
      }


    },
    async getPepole()
    {
      var param={
        no:this.no
      }
      const res = await getPepole(param);
      if(res.code==200)
      {
       this.people=res.data.rows;

      }
    },
    async savePepole()
    {
      var param={
        name:this.form.name,
        shenfen:this.form.shenfen,
        no:this.no
      }
      const res = await savePepole(param);
      if(res.code==200)
      {
        ElMessage({
          type: 'success',
          message: `添加成功`,
        })
        this.addSqr=false;
       this.getPepole();

      }
    },
    addSave()
    {
        this.savePepole();
    },
    add()
    {
      //添加申请人
       this.addSqr=true;

    },
    sub()
    {
      if(!this.sqr && this.sqr!=0)
      {
        ElMessage({
          message:'请选择或者添加一个申请人',
          type: 'error',
          duration:2000,
          offset:200

        });
        return false;
      }

      if(!this.name)
      {
        ElMessage({
          message:'请输入姓名',
          type: 'error',
          duration:2000,
          offset:200

        });
        return false;
      }
      if(!this.phone)
      {
        ElMessage({
          message:'请输入手机号码',
          type: 'error',
          duration:2000,
          offset:200

        });
        return false;
      }
      if (!/^1(3|4|5|6|7|8|9)[0-9]\d{8}$/.test(this.phone)) {
        ElMessage({
          message:'请输入正确的手机号码',
          type: 'error',
          duration:2000,
          offset:200

        });
        return false;
      }

      if(this.allPrice<=0 || isNaN(this.allPrice))
      {
        ElMessage({
          message:'价格错误',
          type: 'error',
          duration:2000,
          offset:200

        });
        return false;
      }
       this.saveOrder();
    },
    getUserinfo(data)
    {

      this.member=data;
      if(this.member.phone) this.phone=this.member.phone
      if(this.member.name) this.phone=this.member.name
    },
    getallPrice()
    {
      var allPrice=this.detail.price*this.num;
      allPrice=allPrice.toFixed(2)

      this.allPrice=allPrice;
    },
    async  getAdData()
    {
      var param={
        ident:'visa_swiper|index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {

        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        this.getWork()
      }
    },
    async  getWork()
    {
      var param={
        work_id:this.work_id
      }
      const res = await getWork(param);
      if(res.code==200)
      {
        this.detail=res.data.rows;
        this.getallPrice();
        document.title = this.detail.title+'-'+this.config.title;
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }
    },

  }
}
</script>

<style scoped>
@media only screen and (max-width: 480px) {
  .el-step.is-simple .el-step__title {
    font-size: 14px !important;
  }
}
.el-radio-group {

  width: 100%;
}
main {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
}
.el-table--enable-row-transition .el-table__body td {
  transition: background-color .25s ease;
}
.el-table td, .el-table th.is-leaf {
  border-bottom: 1px solid #ebeef5;
}
.el-table td, .el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

.el-input__inner:hover {
  border-color: #c0c4cc;
}
.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}

main .el-breadcrumb {
  padding: 20px 0px;

}

main .el-steps {
  margin: 10px 0px 0px;
}

main .el-steps.el-steps--simple {
  background-color: transparent;
}

main section {
  background-color: rgb(255, 255, 255);
  margin: 10px 0px 0px;
  padding: 0px 20px 20px;
  border-radius: 5px;
}

main section h4 {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  color: #3d92d7;
}

main .order .el-table .bold {
  color: var(--err);
  font-size: var(--fs_l);
  font-weight: 700;
}

main .order .el-table .bold::before {
  content: "¥";
  padding: 0px 5px 0px 0px;
  font-size: var(--fs_s);
}

main .order .el-table .link {
  color: var(--main);
  cursor: pointer;
  text-decoration: underline;
}

main .order ul.data {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px 0px;
  width: 100%;
}

main .order ul.data li {
  width: 50%;
  display: inline-flex;
  align-items: center;
  margin: 20px 0px 0px;
}

main .order ul.data li > span {
  color: var(--txt_placeholder);
  white-space: nowrap;
  flex: 0 0 auto;
  text-indent: 10px;
  width: 120px;
}

main .order ul.data li .el-input {
  width: 300px;
}

main .order ul.data li.max {
  width: 100%;
}

main .order ul.data li.max .tags {
  padding: 0px 0px 0px 10px;
}

main .order ul.data li.max .tags .el-tag {
  margin: 0px 0px 0px 10px;
}

main .order > figure {
  padding: 20px 0px;
  margin: 30px 0px 0px;
}

main .order > figure p {
  padding: 0px 0px 10px;
  display: flex;
  align-items: center;
}

main .order > figure p .title {
  color: var(--main);
  font-weight: 700;
  padding: 0px 0px 0px 10px;
}

main .order .people p {
  font-weight: 700;
  color:#3d92d7;
  padding: 0px 0px 0px 10px;
}

main .post dl {
  display: flex;
  flex-wrap: wrap;
}

main .post dl dt {
  width: 100%;
  padding: 10px 0px;
  flex: 0 0 auto;
}

main .post dl dt p.hint {
  display: inline-flex;
  font-size: var(--fs_s);
  color: var(--txt_placeholder);
  padding: 0px 0px 0px 30px;
}

main .post dl dt p.hint b {
  color: var(--err);
  font-weight: 700;
}

main .post dl dt p.hint b::before {
  content: "¥";
  padding-right: 3px;
}

main .post dl dd {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px;
}

main .post dl dd figure {
  display: block;
  width: 100%;
}

main .post dl dd figure .el-checkbox {
  padding: 10px 0px;
}

main .post dl dd figure + figure {
  margin: 30px 0px 0px;
}

main .pay {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 20px;
  margin-bottom: 30px;
}

main .pay figure p {
  padding: 10px 0px 0px;
  width: 100%;
}

main .pay figure p span {
  width: 100px;
  color: #e6a23c;
}

main .pay figure p b {
  color:#e6a23c;
}

main .pay figure p b::before {
  content: "¥";
  padding: 0px 5px 0px 0px;
  font-size: 14px;
}

main .pay figure p b:empty::before {
  content: "--";
}

main .pay figure p:last-of-type b {
  color: #e6a23c;
  font-size: 32px;
}

main .pay figure p:last-of-type b::before {
  font-size:14px;
}

main .pay .btn {
  text-align: right;
}

main .pay .btn .el-checkbox {
  display: block;
}

main .pay .btn .el-button {
  margin: 10px 0px 0px;
  width: 240px;
  flex: 0 0 auto;
}

main .serve > figure {
  padding: 20px 0px;
}

main .serve > figure p {
  padding: 0px 0px 10px;
  display: flex;
  align-items: center;
}

main .serve > figure p .title {
  color: var(--main);
  font-weight: 700;
  padding: 0px 0px 0px 10px;
}

main .serve .invoice p .hint {
  color: var(--txt_placeholder);
  font-size: var(--fs_s);
  padding: 0px 0px 0px 30px;
}

main .serve ul.data {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px 0px;
}

main .serve ul.data li {
  width: 50%;
  display: inline-flex;
  align-items: center;
  margin: 20px 0px 0px;
}

main .serve ul.data li > span {
  color: var(--txt_placeholder);
  white-space: nowrap;
  flex: 0 0 auto;
  text-indent: 10px;
  width: 120px;
}

main .serve ul.data li .el-input {
  width: 300px;
}

main .serve ul.data li.max {
  width: 100%;
}

main .serve ul.data li.max .tags {
  padding: 0px 0px 0px 10px;
}

main .serve ul.data li.max .tags .el-tag {
  margin: 0px 0px 0px 10px;
}

main .coupon ul {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-height: calc(100% - 50px);
  overflow: auto;
}

main .coupon ul li {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(249, 249, 249);
  border-radius: 5px;
  width: 300px;
  margin-bottom: 15px;
  padding: 10px;
  min-height: 60px;
  box-sizing: border-box;
}

main .coupon ul li:nth-of-type(3n-1) {
  margin: 0px 15px 15px;
}

main .coupon ul li figure {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--txt_secondary);
}

main .coupon ul li figure .price {
  flex: 0 0 auto;
}

main .coupon ul li figure .price span {
  display: block;
  text-align: center;
}

main .coupon ul li figure .price span:first-of-type {
  color: var(--err);
  font-size: 18px;
  font-weight: 700;
  padding: 0px 0px 3px;
}

main .coupon ul li figure .price span:first-of-type::before {
  content: "¥";
  font-size: 0.8em;
  padding: 0px 2px 0px 0px;
}

main .coupon ul li figure .info {
  padding: 0px 0px 0px 10px;
}

main .coupon ul li figure .info span {
  display: block;
}

main .coupon ul li figure .info span.title {
  font-size: 14px;
  font-weight: 700;
  color: rgb(35, 35, 35);
  padding: 0px 0px 5px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 130px;
}

main .coupon ul li .icon-gou {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  font-size: 10px;
  color: rgb(255, 255, 255);
}

main .coupon ul li .icon-gou.on {
  background-color: var(--err);
}

main .otherWrap .otherTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

main .otherWrap .addupExtraInfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

main .otherWrap .addupExtraInfo figcaption {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  font-size: var(--fs_xl);
  color: var(--err);
}

main .otherWrap .addupExtraInfo figcaption span {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  main {
    padding: 0px !important;
  }

  main .pay {
    flex-wrap: wrap;
  }

  main .pay figure {
    width: 100%;
  }

  main .pay .btn {
    width: 100%;
  }

  main .pay .btn .el-checkbox {
    white-space: pre-wrap;
  }
}

@media only screen and (max-width: 480px) {
  main .order ul.data li {
    width: 100% !important;
  }
}

</style>
