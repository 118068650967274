<template>
  <insideHeader  :config="config" :nav="nav" @getUserinfo="getUserinfo"></insideHeader>

  <main>
    <leftItem></leftItem>
    <section  class="user_info"><header >
    <figure >
      <el-avatar :size="50" :src="member.avatarUrl"/><p ><b > <text v-if="member.nickName">{{ member.nickName }}</text>
      <text v-else-if="member.phone">{{ member.phone }}</text>
      <text v-else>会员</text></b><span >手机号码：{{ member.phone }}</span></p></figure>
    </header> </section></main>
  <allFooter :config="config" :footer="footer"></allFooter>
</template>

<script>
import insideHeader from "@/components/header/inside_header";
import leftItem from "@/components/member/left_item";
import allFooter from "@/components/footer/all_footer";
import { getAd,getConfig} from '@/config/api';
export default {
  name: "member",
  components:{
    insideHeader,
    leftItem,
    allFooter

  },
  data() {
    return {
      no:'',
      nav: [],
      footer: [],
      config: {},
      member:{}
    }
  },
  mounted()
  {
    this.getConfigData()
    this.getAdData();

  },
  created() {

    let no= localStorage.getItem('no')
    if(!no || no=='')
    {
      this.$router.push({
        path: '/login'
      })
    }

    if(this.$route.query.id) {
      this.id = this.$route.query.id;

    }
  },
  methods:{
    getUserinfo(data)
    {
      console.log('userinfo',data);
      this.member=data;
    },
    async  getAdData()
    {
      var param={
        ident:'index_nav|footer'
      }
      const res = await getAd(param);
      if(res.code==200)
      {

        this.nav=res.data.rows.index_nav
        this.footer=res.data.rows.footer
      }
    },
    async  getConfigData()
    {
      var param={

      }
      const res = await getConfig(param);
      if(res.code==200)
      {
        this.config=res.data.rows;
        document.title ='会员中心-'+this.config.title;

        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);

      }
    },


  }
}
</script>

<style scoped>
.el-menu{
  width: 200px;
  flex: none;
  box-shadow: inset 0 5px 5px 0 rgba(0,0,0,.05)
}

main {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 0;
  display: flex
}

main .user_info {
  width: 1000px;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  min-height: 500px;
}

main .user_info header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 0
}

main .user_info header figure {
  display: inline-flex;
  align-items: center
}

main .user_info header figure .img {
  position: relative;
  width: 100px;
  height: 100px
}

main .user_info header figure .img img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%
}

main .user_info header figure .img span {
  position: absolute;
  left: 50%;
  bottom: -5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px 15px;
  border-radius: 30px;
  border: 2px solid #fff;
  background-color: var(--main);
  transform: translateX(-50%);
  font-size: var(--fs_l);
  font-weight: 500;
  color: #fff;
  white-space: nowrap
}

main .user_info header figure p {
  padding: 0 0 0 20px
}

main .user_info header figure p b {
  font-size: var(--fs_xxl);
  color: var(--txt_bold)
}

main .user_info header figure p span {
  display: block;
  font-size: var(--fs_l);
  color: var(--txt_secondary);
  padding: 10px 0 0
}

main .user_info header .wallet {
  text-align: right
}

main .user_info header .wallet p {
  font-size: var(--fs_l)
}

main .user_info header .wallet p span {
  color: var(--txt_secondary)
}

main .user_info header .wallet p b {
  color: var(--err);
  font-size: 32px
}

main .user_info header .wallet p b:before {
  content: "Â¥";
  font-size: var(--fs_l);
  padding: 0 5px 0 0
}

main .user_info header .wallet .btn {
  margin: 10px 0 0
}

main .user_info .order {
  background-color: #fff;
  padding: 10px 20px 20px;
  border-radius: 5px
}

main .user_info .order h3 {
  font-size: var(--fs_l);
  font-weight: 700;
  color: var(--main);
  display: flex;
  align-items: center;
  height: 40px
}

main .user_info .order nav {
  display: flex;
  align-items: center;
  margin: 20px 0 0
}

main .user_info .order nav .option {
  text-align: center;
  padding: 10px;
  margin: 0 20px;
  cursor: pointer
}

main .user_info .order nav .option i {
  font-size: 32px
}

main .user_info .order nav .option span {
  display: block;
  color: var(--txt_secondary);
  padding: 10px 0 0
}

</style>
